// extracted by mini-css-extract-plugin
export var animateBackground = "superCompressedLiteraryIllustration-module--animate-background--HSgDI";
export var article = "superCompressedLiteraryIllustration-module--article--dQBRH";
export var desc = "superCompressedLiteraryIllustration-module--desc--S11mB";
export var desc2 = "superCompressedLiteraryIllustration-module--desc2--zFIDV";
export var desc3 = "superCompressedLiteraryIllustration-module--desc3--EFGby";
export var eachTitle = "superCompressedLiteraryIllustration-module--eachTitle--iV3e7";
export var openButton = "superCompressedLiteraryIllustration-module--openButton--HGt0S";
export var openButton2 = "superCompressedLiteraryIllustration-module--openButton2--iYFwx";
export var openButtonDiv = "superCompressedLiteraryIllustration-module--openButtonDiv--+zpzm";
export var openButtonDiv2 = "superCompressedLiteraryIllustration-module--openButtonDiv2--iGHRX";
export var panel = "superCompressedLiteraryIllustration-module--panel--P3aTv";